<template>
  <!-- 剧本录入 -->
  <div class="entry">
    <!-- 头部栏 -->
    <el-card class="entry-headers">
      <div class="headers-content">
        <el-button
          @click="toMarket"
          style="margin-right: calc(20% - 24px)"
          type="primary"
          plain
          size="small"
          icon="el-icon-arrow-left"
        >
        </el-button>
        <div class="flex-row flex-ac">
          <span style="font-size: 18px" class="m-r-10">一级用户：</span>
          <img class="fission-avator m-r-5" :src="user.headerImg" />
          <div class="up-name m-r-20">{{user.nickname}}</div>
          <div class="btn flex-row flex-cc m-r-20 pointer" v-copy="user.nickname">复制名称</div>
          <div class="btn flex-row flex-cc pointer" v-copy="user.code">复制ID</div>
        </div>
      </div>
    </el-card>

    <!-- 内容栏 -->
    <div class="entry-content">
      <div class="content">
        <span class="row1">1 </span>
        <div class="row2">
          <div class="row2-left">
            <el-card class="row2-left-card">
              <div
                :class="
                  curTab === item.id
                    ? 'navigation-item-choose'
                    : 'navigation-item'
                "
                @click="curTab = item.id"
                v-for="item in asides"
                :key="item.id"
              >
                {{ item.name }}
              </div>
            </el-card>
          </div>

          <fission-data
            v-if="curTab === 1"
            :fissionId="fissionId"
            @changeUser="changeUser"
          />
          <fission-relation v-if="curTab === 2" :fissionId="fissionId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FissionData from "./fissionData.vue";
import FissionRelation from "./fissionRelation.vue";
export default {
  components: {
    FissionData,
    FissionRelation,
  },
  created() {
    this.fissionId = this.$route.query.id;
  },
  data() {
    return {
      asides: [
        { id: 1, name: "裂变概况" },
        { id: 2, name: "裂变关系" },
      ],
      curTab: 1,
      fissionId: "",
      user: {},
    };
  },
  methods: {
    toMarket() {
      this.$router.push({ name: "market", params: { tab: 2 } });
      // this.$router.go(-1)
    },
    changeUser(detail) {
      this.user = detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.fission-avator {
  width: 30px;
  height: 30px;
  background: #efeef4;
  border-radius: 15px;
}
.up-name {
  width: 192px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn {
  background: #7f76bd;
  width: 62px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  font-size: 14px;
}
.entry {
  .entry-headers {
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .headers-content {
      width: 1366px;
      display: flex;
      align-items: center;
    }
  }
  .entry-content {
    min-height: calc(100vh - 70px);
    width: 100%;
    padding-top: 70px;
    background: rgba(248, 247, 255, 1);
    display: flex;
    justify-content: center;
    .content {
      width: 1366px;
      .row1 {
        opacity: 0;
      }
      .row2 {
        margin-top: 10px;
        display: flex;
        height: calc(100vh - 60px - 150px);
        .row2-left {
          width: 20%;
          height: 100%;
          .row2-left-card {
            box-sizing: border-box;
            padding: 15px;
            height: 100%;
            .navigation-item {
              margin-top: 12px;
              padding: 12px;
              color: rgba(153, 153, 153, 1);
              border-radius: 4px;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .navigation-item-choose {
              margin-top: 12px;
              background: rgba(127, 118, 189, 1);
              padding: 12px;
              color: #fff;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .item-people {
              background: rgba(250, 249, 255, 1);
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .people {
                cursor: pointer;
                min-width: 100px;
                text-align: center;
                margin: 5px 0;
                padding: 4px;
                border-radius: 4px;
                font-size: 14px;
              }
              .people-choice {
                background: rgba(236, 234, 250, 1);
                font-weight: bold;
              }
            }
          }
        }
        .row2-right {
          width: calc(80% - 24px);
          margin-left: 24px;
          height: 100%;
        }
      }
    }
  }
}
</style>