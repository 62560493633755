<template>
  <div class="row2-right flex-cloumn" style="position: relative">
    <div style="height: 30px" class="flex-row flex-ac space-between m-b-8">
      <div>
        <div
          v-for="item in tab"
          :key="item.id"
          @click="curTab = item.id"
          :class="{ 'cur-item': true, 'cur-item-active': curTab === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div style="height: 30px" class="flex-row flex-ac m-b-8 space-between">
      <div>
        <el-select
          size="mini"
          style="width: 120px"
          class="m-r-10"
          placeholder="排序方式"
          v-model="filter1.sort"
          @change="getFissions"
        >
          <el-option
            v-for="(item, index) in sorts"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          class="m-r-10"
          size="mini"
          style="width: 120px"
          v-model="filter1.displayLevel"
          @change="getFissions"
        >
          <el-option
            v-for="item in levels"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <el-input
        size="mini"
        placeholder="搜索OPEN ID/用户名称"
        style="width: 200px"
        v-model="filter1.searchName"
        @input="getFissions"
      />
    </div>
    <el-card class="flex-1" style="position: relative">
      <div>
        <div class="flex-row table-header" style="padding-left: 40px">
          <div
            v-for="(item, index) in fissionHeader"
            :key="index"
            :style="item.style"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          class="item"
          @click="openDialog('addUrl', item)"
          v-for="(item, i) in list"
          :key="i"
          :style="{
            background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
          }"
        >
          <div class="item-index">
            {{ i + 1 }}
          </div>
          <div
            v-for="(item2, index2) in fissionBody"
            :key="index2"
            :class="item2.class"
            :style="item2.style"
          >
            <span v-if="index2 !== 8">{{ item[item2.key] }}</span>
            <div @click.stop v-else v-copy="item.code">{{ item2.key }}</div>
          </div>
        </div>
        <div class="page-text" v-if="showPageText">请翻页</div>
      </div>
    </el-card>
    <div class="page-box">
      <el-pagination
        :total="count"
        :current-page="filter1.currPage"
        :page-size="filter1.limit"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { debounce } from "@tools";
export default {
  props: {
    fissionId: String,
  },
  data() {
    this.fissionHeader = [
      { name: "层级", style: { width: "70px" }, class: "" },
      {
        name: "用户名称",
        style: { width: "250px", textAlign: "left" },
        class: "",
      },
      { name: "访问(次)", style: { width: "100px" }, class: "" },
      { name: "授权(人)", style: { width: "100px" }, class: "" },
      { name: "游戏(人)", style: { width: "100px" }, class: "" },
      { name: "付费(人)", style: { width: "100px" }, class: "" },
      { name: "付费额(元)", style: { width: "150px" }, class: "" },
      { name: "ID", style: { width: "150px" }, class: "" },
      { name: "操作", style: { width: "150px" }, class: "" },
      { name: "授权日期", style: { width: "200px" }, class: "" },
    ];
    this.fissionBody = [
      {
        key: "displayLevel",
        style: { width: "50px", textAlign: "right" },
        class: "item-message shenglue-1",
      },
      {
        key: "nickName",
        style: { width: "300px" },
        class: "item-message shenglue-1",
      },
      {
        key: "visitQuantity",
        style: { width: "100px" },
        class: "item-message shenglue-1",
      },
      {
        key: "authQuantity",
        style: { width: "100px" },
        class: "item-message shenglue-1",
      },
      {
        key: "gameQuantity",
        style: { width: "100px" },
        class: "item-message shenglue-1",
      },
      {
        key: "paidQuantity",
        style: { width: "80px" },
        class: "item-message shenglue-1",
      },
      {
        key: "paidSales",
        style: { width: "150px" },
        class: "item-message shenglue-1",
      },
      {
        key: "code",
        style: { width: "130px" },
        class: "item-message shenglue-1",
      },
      {
        key: "复制ID",
        style: { width: "110px", textAlign: "center" },
        class: "position-btn bg",
      },
      {
        key: "authTime",
        style: { width: "200px", textAlign: "center" },
        class: "item-message shenglue-1",
      },
    ];
    return {
      curTab: 1,
      filter1: {
        displayLevel: 3,
        id: "",
        searchName: "",
        sort: 4,
        limit: 10,
        currPage: 1,
      },
      list: [],
      count: 0,
      tab: [{ id: 1, name: "层级列表" }],
      levels: [
        { label: "展示2级", value: 2 },
        { label: "展示3级", value: 3 },
        { label: "展示全部", value: "" },
      ],
      sorts: [
        { value: 1, label: "层级降序" },
        { value: 2, label: "授权降序" },
        { value: 3, label: "付费额降序" },
        { value: 4, label: "日期降序" },
      ],
    };
  },
  created() {
    this.getFissions();
  },
  methods: {
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    handleCurrentChange(page) {
      this.filter1.page = page;
    },
    getFissions: debounce(async function () {
      this.loading = true;
      Object.assign(this.filter1, { id: this.fissionId });
      const data = await this.$api.market
        .getFissionRelationList(this.filter1)
        .finally(() => {
          this.loading = false;
        });
      this.list = data.data;
      this.count = data.count;
    }, 300),
  },
  computed: {
    showPageText() {
      const { currPage, limit } = this.filter1;
      return this.count !== 0 && Math.ceil(this.count / limit) !== currPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.cur-item {
  width: 96px;
  height: 24px;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
  margin-right: 35px;
}

.cur-item-active {
  color: #fff;
  background: #7f76bd;
}
.btn {
  background: #7f76bd;
  width: 62px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  font-size: 14px;
}
.bg-white {
  background: #fff;
  line-height: 28px;
}

.fission-label {
  padding: 3px 8px;
  background: #eceafa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 10px;
  width: 70px;
  text-align: center;
  height: 22px;
}
.table-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 50px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.item {
  color: #333333;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.item-message {
  margin-left: 10px;
  width: 30%;
}
.shenglue-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item-index {
  margin-left: 10px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #c5c0e0;
  color: #c5c0e0;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}

.bg {
  background: #7f76bd;
  color: #fff;
}
.position-btn {
  border: 1px solid #c5c0e0;
  width: 80px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 14px;
}

.page-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
}
.page-text {
  color: #999999;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 10px;
}
</style>