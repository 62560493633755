<template>
  <div class="row2-right flex-cloumn">
    <div style="height: 30px" class="flex-row flex-ac space-between m-b-8">
      <div>
        <div
          v-for="item in tab"
          :key="item.id"
          @click="curTab = item.id"
          :class="{ 'cur-item': true, 'cur-item-active': curTab === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="flex-row flex-ac">
        <span style="font-size: 18px">此用户上级：</span>
        <!-- <div v-if="upUser.headerImg" class="fission-avator m-r-5"></div> -->
        <img
          v-if="upUser.headerImg"
          class="fission-avator m-r-5"
          :src="upUser.headerImg"
        />
        <div class="up-name m-r-20">{{ upUser.nickname }}</div>
        <div v-if="upUser.code" v-copy="upUser.nickname" class="btn flex-row flex-cc m-r-20 pointer">
          复制名称
        </div>
        <div v-if="upUser.code" v-copy="upUser.code" class="btn flex-row flex-cc pointer">
          复制ID
        </div>
      </div>
    </div>
    <div style="height: 30px" class="flex-row flex-ac m-b-8">
      <el-select
        class="m-r-10"
        size="mini"
        style="width: 120px"
        v-model="filter1.displayLevel"
        @change="getFissions"
      >
        <el-option
          v-for="item in levels"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        ></el-option>
      </el-select>
      <div class="bg-white">统计时间：</div>
      <el-date-picker
        style="border: none; width: 300px"
        size="mini"
        v-model="filterTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        @change="getFissions"
        value-format="timestamp"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        :unlink-panels="true"
      >
      </el-date-picker>
    </div>
    <el-card class="flex-1" v-loading="loading">
      <div>
        <div class="flex-row flex-as">
          <div class="fission-label">裂变人流</div>
          <el-table
            class="m-b-20"
            border
            :header-cell-style="thStyle"
            :cell-style="cellStyle"
            style="width: auto; flex: 0 1 auto"
            :data="list.flow"
          >
            <el-table-column prop="title" label="" width="80">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in peopleOptions"
              :key="index"
              :label="item.name"
              width="130"
            >
              <template slot-scope="scope">
                {{
                  `${scope.row[item.key]}${
                    scope.row[item.key] === "-" ? "" : item.unit
                  }`
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-row flex-as">
          <div class="fission-label">交易支付</div>
          <el-table
            class="m-b-20"
            border
            :header-cell-style="thStyle"
            :cell-style="cellStyle"
            style="width: auto; flex: 0 1 auto"
            :data="list.pay"
          >
            <el-table-column prop="title" label="" width="80">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in bussinessOptions"
              :key="index"
              :label="item.name"
              width="130"
            >
              <template slot-scope="scope">
                {{
                  `${scope.row[item.key]}${
                    scope.row[item.key] === "-" ? "" : item.unit
                  }`
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { debounce, timestampToDate } from "@tools";
export default {
  props: {
    fissionId: String,
  },
  mounted() {
    this.getFissions();
  },
  data() {
    this.pickerOptions = {
      shortcuts: [
        {
          text: "最近7天",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近10天",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一年",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
      disabledDate(time) {
        return (
          time.getTime() < new Date("2020-08-10").getTime() ||
          time.getTime() > new Date().getTime()
        );
      },
    };
    return {
      loading: false,
      curTab: 1,
      filter1: {
        displayLevel: 3,
        beginTime: "",
        endTime: "",
        id: "",
      },
      tab: [{ id: 1, name: "裂变数据" }],
      levels: [
        { label: "展示2级", value: 2 },
        { label: "展示3级", value: 3 },
        { label: "展示全部", value: "" },
      ],
      peopleOptions: [
        { name: "裂变层级", key: "key1", unit: "级" },
        { name: "授权用户数", key: "key2", unit: "人" },
        { name: "游戏用户数", key: "key3", unit: "人" },
        { name: "游戏转化率", key: "key4", unit: "%" },
      ],
      bussinessOptions: [
        { name: "自身付费额", key: "key1", unit: "元" },
        { name: "下级付费额", key: "key2", unit: "元" },
        { name: "付费用户数", key: "key3", unit: "人" },
        { name: "付费转化率", key: "key4", unit: "%" },
      ],
      filterTime: [
        new Date().getTime() - 3600 * 1000 * 24 * 30,
        new Date().getTime(),
      ],
      list: {
        flow: [],
        pay: [],
        upFissionUserDataVO: null
      },
    };
  },
  computed: {
    upUser() {
      const upFissionUserDataVO = this.list.upFissionUserDataVO;
      if (upFissionUserDataVO) {
        return upFissionUserDataVO;
      } else {
        return {
          nickname: "无",
        };
      }
    },
  },
  methods: {
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    getFissions: debounce(async function () {
      this.loading = true;
      let beginTime = timestampToDate(this.filterTime[0], false),
        endTime = timestampToDate(this.filterTime[1], false);
      Object.assign(this.filter1, { beginTime, endTime, id: this.fissionId });
      const data = await this.$api.market
        .getFissionDataList(this.filter1)
        .finally(() => {
          this.loading = false;
        });
      this.$emit("changeUser", data.fissionUserDataVO);
      let flow = [{ title: "总数" }, { title: "日平均值" }];
      data.fissionFlowList.forEach((item) => {
        flow[0][`key${item.no}`] = item.sum;
        flow[1][`key${item.no}`] =
          item.no === 1 || item.no === 4 ? "-" : item.dayAvg;
      });
      this.list.flow = flow;
      let pay = [{ title: "总数" }, { title: "日平均值" }];
      data.transactionPaidList.forEach((item) => {
        pay[0][`key${item.no}`] = item.sum;
        pay[1][`key${item.no}`] = item.no === 4 ? "-" : item.dayAvg;
      });
      this.list.pay = pay;
      this.list.upFissionUserDataVO = data.upFissionUserDataVO
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.cur-item {
  width: 96px;
  height: 24px;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
  margin-right: 35px;
}

.cur-item-active {
  color: #fff;
  background: #7f76bd;
}

.fission-avator {
  width: 30px;
  height: 30px;
  background: #efeef4;
  border-radius: 15px;
}
.up-name {
  width: 192px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn {
  background: #7f76bd;
  width: 62px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  font-size: 14px;
}
.bg-white {
  background: #fff;
  line-height: 28px;
}

.fission-label {
  padding: 3px 8px;
  background: #eceafa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 10px;
  width: 70px;
  text-align: center;
  height: 22px;
}
</style>